import { base } from '@theme-ui/presets'
import { merge } from 'theme-ui'

import { breakpoints } from './breakpoints'
import { mediaQueries } from './media-queries'
import { space } from './space'

export const colors = {
  primary: `#DC395F`,
  green: `#0CCA4A`,
  darkGray: `#6E7B91`,
  mute: `#F6F6FC`,
  gray: `#EFEFF7`,
  darkBlue: `#263144`,
  raisinBlack: `#1F2633`,
  text: `#F6F6FC`,
  background: `#1C1C1C`,
  documentation: {
    text: `#F6F6FC`,
    background: `#242D3C`,
  },
}

export const constants = {
  headerHeight: `96.5312px`,
  containerMaxWidth: `1064px`,
  textContentMaxWidth: `calc(1000px - 128px)`,
}

const lineHeights = {
  solid: 1,
  dense: 1.2,
  heading: 1,
  body: 1.444444,
  default: 1.5,
  loose: 1.75
}

const fontSizes = [12, 14, 16, 18, 24, 28, 36, 48, 64, 96]

const theme = merge(base, {
  lineHeights,
  space,
  colors,
  breakpoints,
  fontSizes,
  fonts: {
    body:
      'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  styles: {
    root: {
      fontFamily: `heading`,
      lineHeight: `body`,
      fontWeight: `body`,
      color: `text`,
      backgroundColor: `background`,
      p: {
        fontSize: [3, 3, 3, 3, 3],
        my: [3],
        lineHeight: `body`,
        color: `inherit`,
      },
      h1: {
        lineHeight: `heading`,
        fontFamily: `heading`,
        letterSpacing: `-2px`,
        fontSize: [5, 5, 5, 5, 6, 7],
      },
      h2: {
        m: 0,
        fontSize: [4, 4, 4, 4, 5, 6],
        fontWeight: `bold`,
        fontFamily: `heading`,
      },
      hr: {
        borderColor: `inherit`,
        borderWidth: `4px`,
        borderRadius: `9999px`,
        borderStyle: `solid`,
        width: `56px`,
        mx: 0,
        mt: 3,
        mb: 4,
      },
    },
    h1: {
      lineHeight: `heading`,
      fontSize: [5, 5, 5, 5, 5, 6],
      color: `inherit`,
    },
    h2: {
      fontWeight: `600`,
      fontSize: [4, 4, 4, 4, 4],
      mt: [4],
      mb: [3],
      color: `inherit`,
    },
    h3: {
      fontSize: [3, 3, 3, 3, 3],
      fontWeight: `600`,
      mt: [4],
      mb: [3],
      color: `inherit`,
    },
    h4: {
      mt: [4],
      mb: [3],
      color: `inherit`,
    },
    p: {
      fontSize: [3, 3, 3, 3, 3],
      my: [3],
      lineHeight: `body`,
      color: `inherit`,
    },
    a: {
      color: `inherit`,
      textDecoration: `underline`,
      transition: `0.2s`,
      "&:hover": {
        color: `green`,
      },
    },
    code: {
      fontFamily: `monospace`,
      bg: `raisinBlack`,
      borderRadius: `3px`,
      fontSize: `80%`,
      py: `2px`,
      px: 2,
    },
  },
  hero: {
    title: {
      my: 0,
      color: `black`,
      fontWeight: `bold`,
      fontSize: 4,
      lineHeight: `heading`,
      [mediaQueries.md]: {
        fontSize: 5,
      },
    },
  },
  text: {
    title: {
      mb: 3,
      mt: 0,
      fontWeight: `bold`,
      fontSize: 5,
      lineHeight: `heading`,
      color: `inherit`,
      [mediaQueries.md]: {
        fontSize: 5,
      },
    },
    subtitle: {
      fontSize: [4, 4, 4, 4],
      mt: 3,
      mb: 3,
      fontWeight: `400`,
      lineHeight: `dense`,
      [mediaQueries.md]: { my: 4 },
    },
  },
  sizes: {
    container: breakpoints.xl,
  },
  layout: {
    container: {
      maxWidth: constants.containerMaxWidth,
    },
    textContainerLarge: {
      maxWidth: constants.textContentMaxWidth,
      ml: 0,
    },
    largeContainer: {
      maxWidth: `1280px`,
    },
  },
  section: {
    theme: {
      dark: {
        color: `white`,
        background: `#1C1C1C`,
      },
      light: {
        color: `#1C1C1C`,
        background: `white`,
      },
    },
  },
  buttons: {
    primary: {
      bg: `primary`,
      color: `white`,
    },
    secondary: {
      bg: `transparent`,
      border: `2.5px solid`,
      borderColor: `text`,
      color: `text`,
      "&:hover": {
        borderColor: `primary`,
        color: `primary`,
      },
    },
    light: {
      bg: `white`,
      color: `#1C1C1C`,
    },
  },
  list: {
    plain: {
      listStyle: `none`,
      m: 0,
      p: 0,
    },
  },
  anchor: {
    plain: {
      textDecoration: `none`,
      color: `inherit`,
      transition: `0.2s`,
    },
  },
})

export default theme